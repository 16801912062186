import { handleMonthlyPmt } from '../handleMonthlyPmt';

// -------------------  $$$$ Split Financing $$$$ ------------------- //
// ----------------- $$$$ Primary Lender Logic $$$$ ------------------- //
export const handlePrimaryLender = (
  active,
  option,
  primaryLender,
  minRate,
  maxRate
) => {
  // Primary Lender is any lender except myMILAN
  // Some lenders require down payment as a percentage (e.g. 5% required down payment for Covered Care)
  // Some lenders require a fixed amount of down payment (e.g. $99 required down payment for HFDT)
  const isPercentage =
    active.lender.downpayment > 0 && active.lender.downpayment < 1;
  primaryLender.requiredDownPmt = isPercentage
    ? active.lender.downpayment * primaryLender.approvedAmount
    : active.lender.downpayment;

  primaryLender.financedAmount =
    primaryLender.approvedAmount - primaryLender.requiredDownPmt;

  primaryLender.monthlyPayment = handleMonthlyPmt(
    primaryLender.approvedAmount,
    primaryLender.requiredDownPmt,
    minRate,
    maxRate,
    option.financePlan
  );
};
