import React, { useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { DISCOUNTS } from '../../constants';
import { QuoteContext } from '..';
import TrashCan from '../../assets/Trash-Can.png';
import { updateOptionProps } from '../../utils';
import {
  isBYOBItem,
  isBYOBApplicable,
  getBYOBApplicableItems,
  getBYOBItems,
} from '../../utils/byob';

const defaultExclusions = ['CSS', 'Prev Tx'];
const PrivateSaleExclusions = [...defaultExclusions, '60%'];
const FullBodyExclusions = [...PrivateSaleExclusions, 'Event'];

function AreaRow({ option, optionKey, area }) {
  const { setOptions, active, clearOption } = useContext(QuoteContext);
  const optionClone = { ...option };
  let optionItems = [...optionClone.items];
  let DISCOUNTS_DROPDOWN =
    area.parentAreaId !== 'bundles'
      ? Object.keys(DISCOUNTS).filter((key) => key !== 'BNDLE')
      : area.id === 'full_body'
      ? Object.keys(DISCOUNTS).filter(
          (key) => !FullBodyExclusions.includes(key)
        )
      : area.parentAreaId === 'bundles'
      ? Object.keys(DISCOUNTS).filter((key) => !defaultExclusions.includes(key))
      : Object.keys(DISCOUNTS);

  // Exclude Prev Tx discount when a Private Sale is selected
  if (active.privateSale !== 'None') {
    DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
      (key) => !PrivateSaleExclusions.includes(key)
    );
  }

  // ---------- CA EVENT Discounts ---------- //
  const isNewClient = active.discount === 'CA EVENT - NEW';
  const isExistingClient = active.discount === 'CA EVENT';
  const isCADiscount = isNewClient || isExistingClient;
  if (!isCADiscount || area.parentAreaId === 'bundles') {
    DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
      (key) => !['CA EVENT - NEW', 'CA EVENT'].includes(key)
    );
  } else {
    if (isExistingClient) {
      DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
        (key) => key !== 'CA EVENT - NEW'
      );
    }
    if (isNewClient) {
      DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
        (key) => key !== 'CA EVENT'
      );
    }
  }
  // ------------------------------------------- //

  // ------ BYOB Discount Logic ------ //
  let byobItems,
    byobApplicableItems = [];
  if (active.discount === 'BYOB') {
    byobItems = getBYOBItems(optionItems);
    byobApplicableItems = getBYOBApplicableItems(optionItems);
  }
  if (
    DISCOUNTS_DROPDOWN.includes('BYOB') &&
    (active.discount !== 'BYOB' ||
      !isBYOBApplicable(area) ||
      byobApplicableItems.length < 3)
  ) {
    DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter((key) => key !== 'BYOB');
  }
  // -------------------------------- //

  const deleteArea = (area, key) => {
    if (optionItems.length === 1) {
      // if the last item is deleted
      clearOption(key);
    } else {
      // ------ BYOB Discount Logic ------ //
      if (
        active.discount === 'BYOB' &&
        isBYOBItem(area) &&
        byobItems.length === 3
      ) {
        optionItems.forEach((item) => {
          if (isBYOBItem(item)) item.discount = '50%';
        });
      }
      // ------ End - BYOB Discount Logic ------ //
      setOptions((prev) => ({
        ...prev,
        [key]: {
          ...optionClone,
          items: [...optionItems.filter((elem) => elem.id !== area.id)],
        },
      }));
    }
  };

  const discountsDropdownHandler = (discount, area, key) => {
    const itemIndex = optionItems.findIndex((elem) => elem.id === area.id);

    // ------ BYOB Discount Logic ------ //
    if (active.discount === 'BYOB' && isBYOBApplicable(area)) {
      if (discount !== 'BYOB' && isBYOBItem(area) && byobItems.length === 3) {
        optionItems.forEach((item) => {
          if (isBYOBItem(item)) item.discount = '50%';
        });
      } else if (discount === 'BYOB' && byobApplicableItems.length >= 2) {
        optionItems.forEach((item) => {
          if (isBYOBApplicable(item)) item.discount = discount;
        });
      }
    }
    // ------ End - BYOB Discount Logic ------ //
    optionItems[itemIndex].discount = discount;
    optionClone.items = [...optionItems];
    setOptions((prev) => ({
      ...prev,
      [key]: updateOptionProps(optionClone, active),
    })); // Don't use the option directly
  };

  useEffect(() => {
    // This will be triggered if the area discount is Prev Tx OR CSS. Then, a Private Sale gets added
    // In that case, default area discount to 50%
    if (active.privateSale !== 'None') {
      if (PrivateSaleExclusions.includes(area.discount)) {
        discountsDropdownHandler('50%', area, optionKey);
      }
    }
  }, [active.privateSale]);

  return (
    <div className='container py-1 border-bottom'>
      <div className='row gx-2'>
        <div className='col d-flex align-items-center'>
          <p className='mb-0 milan-fs-7'>{area.name}</p>
        </div>
        <div className='col-auto d-flex align-items-center'>
          <Formik
            initialValues={{ single_discount: area.discount }}
            enableReinitialize
          >
            <Form>
              <Field
                as='select'
                id='single_discount'
                name='single_discount'
                className='form-select py-1'
                style={{
                  width: '135px',
                  backgroundColor: 'inherit',
                  fontSize: '12px',
                }}
                onChange={(e) =>
                  discountsDropdownHandler(e.target.value, area, optionKey)
                }
              >
                {DISCOUNTS_DROPDOWN.map((discountKey, d) => (
                  <option key={d} value={discountKey}>
                    {discountKey === 'CSS' && active.preSale ? (
                      <>
                        {area.parentAreaId.includes('upgrade')
                          ? 'Upgrade'
                          : 'CSS'}
                      </>
                    ) : (
                      discountKey
                    )}
                  </option>
                ))}
              </Field>
            </Form>
          </Formik>
        </div>
        <div className='col-auto print-hide d-flex align-items-center'>
          <span
            className='pointer px-2 py-1'
            onClick={() => deleteArea(area, optionKey)}
          >
            <img
              src={TrashCan}
              alt='trash can icon'
              style={{ maxWidth: '18px' }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default AreaRow;
