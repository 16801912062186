import React from 'react';
import Card from 'react-bootstrap/Card';
import { USCurrentStyleDecimal } from '../../utils';

function OptionOutputsBottom({ option, active }) {
  const minLoanRequired = active.lender.minLoanRequired[option.financePlan];

  return (
    <Card.Body
      className={`output position-relative pt-2 pb-0 px-0 ${
        option.items.length > 0 ? 'milan-bg-secondary' : ''
      } ${active.preSale ? 'milan-bg-primary-light' : ''}`}
    >
      {option.items.length > 0 && (
        <>
          <p className='milan-text-navy mb-1'>
            <span>Original Cost</span>
            <span className='float-end'>
              <span className='text-decoration-line-through milan-text-navy'>
                {USCurrentStyleDecimal(option.totalOriginalCost)}
              </span>
            </span>
          </p>
          {active.privateSale !== 'None' && option.privateSale !== 0 && (
            <p className='milan-text-navy mb-1'>
              <span>Private Sale</span>{' '}
              <span className='float-end'>${option.privateSale}</span>
            </p>
          )}
          <p className='mb-1 fw-bold'>
            <span> Total Discount Savings</span>{' '}
            <span className='float-end'>
              {USCurrentStyleDecimal(option.totalSavings)}
            </span>
          </p>
          <p className='milan-text-tur fw-bold mb-1 fs-5 lh-sm milan-bg-light-tur'>
            <span>Your Cost</span>{' '}
            <span className='float-end'>
              {USCurrentStyleDecimal(option.finalTotalCost)}
            </span>
          </p>
          {option.todaysPayment > 0 && (
            <>
              {option.requiredDownPmt > 0 && (
                <p className='milan-text-navy mb-1'>
                  <span>Required Down Payment</span>
                  <span className='float-end'>
                    {USCurrentStyleDecimal(option.requiredDownPmt)}
                  </span>
                </p>
              )}
              {option.additionalDownPmt > 0 && (
                <p className='milan-text-navy mb-1'>
                  <span>Additional Down Payment</span>{' '}
                  <span className='float-end'>
                    {USCurrentStyleDecimal(option.additionalDownPmt)}
                  </span>
                </p>
              )}
              <p className='fw-bold mb-1 lh-sm'>
                <span>Total Financed</span>{' '}
                <span className='float-end'>
                  {USCurrentStyleDecimal(option.totalFinanced)}
                </span>
              </p>
            </>
          )}
          {minLoanRequired > 0 && option.totalFinanced < minLoanRequired && (
            <p className='mb-1 fine-print milan-text-red text-center print-hide'>{`A minimum of ${USCurrentStyleDecimal(
              minLoanRequired
            )} total financed is required`}</p>
          )}
        </>
      )}
    </Card.Body>
  );
}

export default OptionOutputsBottom;
