// When the Down Payment input is enabled and amount entered is larger than the requiredDownPmt
/**
 * This function splits the total down payment entered by user into required & additional down payments. It also calculates the total financed
 * @param {number} cost final total cost
 * @param {number} totalDownPmt entered down payment amount
 * @param {number} downpayment lender minimum required percentage / percentage or fixed number
 * @returns {object} additionalDownPmt, requiredDownPmt, totalFinanced
 */
export const calcRequiredDownPmt = (cost, totalDownPmt, downpayment) => {
  let additionalDownPmt = 0;
  let requiredDownPmt = 0;
  const isPercentage = downpayment > 0 && downpayment < 1;
  if (isPercentage) {
    // percentage
    additionalDownPmt = (totalDownPmt - cost * downpayment) / (1 - downpayment);
    requiredDownPmt = totalDownPmt - additionalDownPmt;
  } else {
    // fixed number
    additionalDownPmt = totalDownPmt - downpayment;
    requiredDownPmt = downpayment;
  }

  return { additionalDownPmt, requiredDownPmt };
};
